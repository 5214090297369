import axios from 'axios';
import localStorage from "redux-persist/es/storage";
import {environment} from "config";
import Logger from "utils/Logger";
import {refreshTokenKeycloak} from "api/keycloak";
import jwt_decode from "jwt-decode";

const axiosServices = axios.create({
    baseURL: environment.API_URL
});

let isRefreshing = false;
let failedQueue = [];

/**
 * Processes the queue of promises by either rejecting them with the specified error or resolving them with the given token.
 * @param {Error} error - The error to reject the promises with, if provided.
 * @param {any} token - The token to resolve the promises with, defaults to null if not provided.
 */
const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });
    failedQueue = [];
};


/**
 * Validates a token by checking its format, expiration, required claims, issuer, and audience.
 *
 * @param {string} token - The token to validate in the format header.payload.signature
 * @returns {boolean} - True if the token is valid, false otherwise
 */
export function validateToken(token) {

    // Check if the token has the correct format (header.payload.signature)
    if (!token || token.split('.').length !== 3) {
        return false;
    }

    try {
        // Decode the payload
        const payload = jwt_decode(token);

        // Check if the token has expired
        const currentTime = Math.floor(Date.now() / 1000); // current time in seconds
        if (payload.exp && currentTime > payload.exp) {
            return false;
        }

        // Validate the presence of required claims
        const requiredClaims = ['iss', 'aud', 'sub'];
        if (!requiredClaims.every(claim => payload.hasOwnProperty(claim))) {
            return false;
        }

        // Validate the issuer
        const expectedIssuer = environment.Token_Issuer;
        if (payload.iss !== expectedIssuer) {
            return false;
        }

        // If all checks pass, the token is considered valid
        return true;

    } catch (error) {

        // If any errors occur during decoding or validation, the token is invalid
        return false;
    }
}


axiosServices.interceptors.request.use(async config => {
    let token = await localStorage.getItem('serviceToken');
    if (token && validateToken(token)) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    config.headers['X-Requested-With-Protocol'] = window.location.protocol;

    return config;
}, function (error) {
    return Promise.reject(error);
});

axiosServices.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    const originalRequest = error.config;

    if (error.response.status === 404) {
        Logger.error(error)
    }

    if (error.response.status === 401 && !originalRequest._retry) {

        if (isRefreshing) {
            return new Promise(function (resolve, reject) {
                failedQueue.push({resolve, reject});
            }).then(token => {
                originalRequest.headers.Authorization = 'Bearer ' + token;
                return axiosServices(originalRequest);
            });
        }

        isRefreshing = true;
        const refreshToken = window.localStorage.getItem('refreshToken');

        return refreshTokenKeycloak(refreshToken).then(data => {

            localStorage.setItem('serviceToken', data.access_token);
            localStorage.setItem('refreshToken', data.refresh_token);
            axiosServices.defaults.headers.common.Authorization = `Bearer ${data.access_token}`;
            originalRequest.headers.Authorization = `Bearer ${data.access_token}`;
            processQueue(null, data.access_token);

            originalRequest._retry = true;
            isRefreshing = false;
            return axiosServices(originalRequest);
        }).catch(err => {

            processQueue(err, null);

            if (err.response && err.response.status === 401) {
                localStorage.removeItem('serviceToken');
                localStorage.removeItem('refreshToken');
            }

            isRefreshing = false;
            throw err;
        });
    }

    if (error.response.status === 502) {
        return new Promise((resolve, reject) => {
            Logger.error(error);
            reject(error);
        });
    }

    return Promise.reject(error);
});

export default axiosServices;