import axios from "axios";
import {environment} from "../config";

export const loginKeycloak: (
    username: string,
    password: string
) => Promise<any> = async (
    username: string,
    password: string
): Promise<any> => {
    try {
        // Create a URLSearchParams object to format the data
        const params = new URLSearchParams();
        params.append("grant_type", "password");
        params.append("username", username);
        params.append("password", password);
        params.append("client_id", environment.KEYCLOAK_CLIENT_ID); // Ensure to include client_id

        const response = await axios.post(
            `${environment.KEYCLOAK_URL}/realms/${environment.KEYCLOAK_REALM}/protocol/openid-connect/token`,
            params.toString(), // Convert to a URL-encoded string
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded", // Set content type
                },
            }
        );

        return response?.data || null; // Return the response data
    } catch (error) {
        console.error("Error logging in:", error);
        throw error; // Rethrow the error for further handling
    }
};

export const refreshTokenKeycloak: (
    refreshToken: string,
) => Promise<any> = async (
    refreshToken: string,
): Promise<any> => {
    try {
        // Create a URLSearchParams object to format the data
        const params = new URLSearchParams();
        params.append("grant_type", "refresh_token");
        params.append("refresh_token", refreshToken);
        params.append("client_id", environment.KEYCLOAK_CLIENT_ID); // Ensure to include client_id

        const response = await axios.post(
            `${environment.KEYCLOAK_URL}/realms/${environment.KEYCLOAK_REALM}/protocol/openid-connect/token`,
            params.toString(), // Convert to a URL-encoded string
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded", // Set content type
                },
            }
        );
        return response?.data || null; // Return the response data
    } catch (error) {
        console.error("Error logging in:", error);
        throw error; // Rethrow the error for further handling
    }
};

export const logoutKeycloak: (
    refreshToken: string,
) => Promise<any> = async (
    refreshToken: string,
): Promise<any> => {
    try {
        // Create a URLSearchParams object to format the data
        const params = new URLSearchParams();
        params.append("refresh_token", refreshToken);
        params.append("client_id", environment.KEYCLOAK_CLIENT_ID); // Ensure to include client_id

        const response = await axios.post(
            `${environment.KEYCLOAK_URL}/realms/${environment.KEYCLOAK_REALM}/protocol/openid-connect/logout`,
            params.toString(), // Convert to a URL-encoded string
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded", // Set content type
                },
            }
        );
        return response?.data || null; // Return the response data
    } catch (error) {
        console.error("Error logging out:", error);
        throw error; // Rethrow the error for further handling
    }
};