import { FormattedMessage } from 'react-intl';
import { IconUser } from '@tabler/icons-react';

// ==============================|| MENU ITEMS - Image PAGE ||============================== //

const icons = {
    IconUser
};
const profilePage = {
    id: 'profile-page',
    title: <FormattedMessage id="Profile" />,
    icon: icons.IconUser,
    type: 'group',
    url: '/profile/observations'
};

export default profilePage;
