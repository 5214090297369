// Logger.js
import log from 'loglevel';

import { environment } from 'config';

// Set log level based on the environment
if (environment.ENVIRONMENT !== 'DEV') {
    log.setLevel('error');
} else {
    log.setLevel('debug'); // Log everything in development
}

// Redirect all console warn messages through the loglevel warn method
console.warn = function(message, ...args) {
    log.warn(message, ...args);
};

// Redirect all console error messages through the loglevel error method
console.error = function(message, ...args) {
    log.error(message, ...args);
};

// Logger wrapper to export logging functions
const Logger = {
    trace: (...args) => log.trace(...args),
    debug: (...args) => log.debug(...args),
    info: (...args) => log.info(...args),
    warn: (...args) => log.warn(...args),
    error: (...args) => log.error(...args),
    setLevel: (level) => log.setLevel(level),
};

export default Logger;
