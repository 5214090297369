import { FormattedMessage } from 'react-intl';

// assets
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';

// ==============================|| MENU ITEMS - Admin PAGE ||============================== //

const icons = {
    AdminPanelSettingsOutlinedIcon
};

const AdminPage = {
    id: 'admin-page',
    title: <FormattedMessage id="admin-page-title" />,
    caption: <FormattedMessage id="admin-page-caption" />,
    icon: icons.AdminPanelSettingsOutlinedIcon,
    type: 'group',
    children: [
        {
            id: 'tools',
            title: <FormattedMessage id="Tools" />,
            type: 'collapse',
            icon: icons.AdminPanelSettingsOutlinedIcon,
            children: [
                {
                    id: 'dashboard',
                    title: <FormattedMessage id="Dashboard" />,
                    type: 'item',
                    url: '/admin/dashboard',
                    target: false,
                }
            ]
        }
    ]
};

export default AdminPage;
