import { FormattedMessage } from 'react-intl';

// assets
import { IconPhotoUp } from '@tabler/icons-react';

// ==============================|| MENU ITEMS - UPLOAD PAGE ||============================== //

const icons = {
    IconPhotoUp
};
const uploadPage = {
    id: 'upload-page',
    title: <FormattedMessage id="Upload" />,
    icon: icons.IconPhotoUp,
    type: 'group',
    url: '/upload'
};

export default uploadPage;
