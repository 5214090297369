
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// project imports
import useAuth from 'hooks/useAuth';
import {isAdmin} from "utils/authHelper";

// ==============================|| Admin GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AdminGuard = ({ children }) => {
    const { isLoggedIn } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {

        if (!isLoggedIn || !isAdmin()) {
            navigate('login', { replace: true });
        }

    }, [isLoggedIn, navigate]);

    return isLoggedIn ? children : null;
};

AdminGuard.propTypes = {
    children: PropTypes.node
};

export default AdminGuard;
