import axiosServices from '../utils/axios';

export const getPublicKey: (
) => Promise<any> = async (
): Promise<any> => {
    const response = await axiosServices.get('/api/keygen/public');
    return response?.data || null;
}

export const registerUser: (
    userData: object
) => Promise<any> = async (
    userData: object
): Promise<any> => {
    const response = await axiosServices.post('/api/register', userData);
    return response || null;
}

export const sendVerifyEmail: (
    username: string
) => Promise<any> = async (
    username: string
): Promise<any> => {
    const response = await axiosServices.put(`/api/register/verify-email?username=${encodeURIComponent(username)}`);
    return response || null;
}

export const sendResetPassword: (
    email: string
) => Promise<any> = async (
    email: string
): Promise<any> => {
    const response = await axiosServices.put(`/api/register/reset-password?email=${encodeURIComponent(email)}`);
    return response || null;
}

export const forgotPassword: (
    userData: object ,
    token: string
) => Promise<any> = async (
    userData: object,
    token: string
): Promise<any> => {
    const response = await axiosServices.post(`/api/forgot-password/reset/${token}`, userData);
    return response?.data || null;
};

export const verifyEmailCode: (
    data: object
) => Promise<any> = async (
    data: object
): Promise<any> => {
    return await axiosServices.post('/api/register/verify-email',data) || null;
}