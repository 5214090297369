import { lazy } from 'react';

// project imports
import GuestGuard from 'utils/route-guard/GuestGuard';
// import MinimalLayout from 'layout/MinimalLayout';
// import NavMotion from 'layout/NavMotion';
import Loadable from 'ui-component/Loadable';
import MainLayout from '../layout/MainLayout';
import UpdatePage from "views/casabio-info/update-page";
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// login routing
const AuthLogin = Loadable(lazy(() => import('../views/system-utils/authentication/Login')));
const ForgotPassword = Loadable(lazy(() => import('../views/system-utils/authentication/ForgotPassword')));
const AuthRegister = Loadable(lazy(() => import('../views/system-utils/authentication/Register')));
const AuthCodeVerification = Loadable(lazy(() => import('../views/system-utils/authentication/CodeVerification')));
const HomePage = Loadable(lazy(() => import('views/home/Home')));
const ExplorePage = Loadable(lazy(() => import('views/explorer/explore-observation/explore-page')));
const VersionPage = Loadable(lazy(()=> import('views/casabio-info/version-page')));
const AboutPage = Loadable(lazy(()=> import('views/casabio-info/about-page')));
const ContactUsPage = Loadable(lazy(()=> import('views/casabio-info/contact-page')));
const NewsPage = Loadable(lazy(()=> import('views/other/news-page')));
const DonateNowPage = Loadable(lazy(()=> import('views/donation/specify-donation-page')));
const DonationPage = Loadable(lazy(()=> import('views/donation/donation-page')));
const CalendarPage = Loadable(lazy(()=> import('views/other/calendar-page')));
// const BookPage = Loadable(lazy(()=> import('views/explorer/explore-books')));
// const IndividualBookPage = Loadable(lazy(()=> import('../views/explorer/explore-books/book-view')));
const ExploreTaxon = Loadable(lazy(()=> import('views/explorer/explore-taxon')));
const IndividualObservationPage = Loadable(lazy(()=> import('views/explorer/explore-observation/observation-view')));
const HackathonPage = Loadable(lazy(()=> import('views/events/hackathon')));
const POPIPolicyPage = Loadable(lazy(()=> import('views/casabio-info/POPI-policy-page')));


// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
    path: '/',
    element: (

            <GuestGuard>
                <MainLayout />
            </GuestGuard>

    ),
    children: [
        {
            path: '/',
            element: <HomePage />
        },
        {
            path: '/login',
            element: <AuthLogin />
        },
        {
            path: '/register',
            element: <AuthRegister />
        },
        {
            path: '/code-verification/:token',
            element: <AuthCodeVerification />
        },
        {
            path: '/home',
            element: <HomePage />
        },
        {
            path: '/browse/observations',
            element: <ExplorePage />
        },
        {
            path: '/forgot-password',
            element: <ForgotPassword />
        },
        {
            path: '/version',
            element: <VersionPage />
        },
        {
            path: '/about',
            element: <AboutPage />
        },
        {
            path: '/contact-us',
            element: <ContactUsPage />
        },
        {
            path: '/news',
            element: <NewsPage/>
        },
        {
            path: '/componentTesting',
            element: <SamplePage/>
        },
        {
            path: '/donations/donate',
            element: <DonateNowPage/>
        },
        {
            path: '/donations',
            element: <DonationPage />
        },
        {
            path: '/calendar',
            element: <CalendarPage/>
        },
        // {
        //     path: '/browse/books',
        //     element: <BookPage />
        // },{
        //     path: '/browse/books/:id',
        //     element: <IndividualBookPage />
        // },
        {
            path: '/browse/taxonomies',
            element: <ExploreTaxon/>
        },
        {
            path: '/browse/observations/:id',
            element: <IndividualObservationPage />
        },
        // {
        //     path: '/events/hackathon',
        //     element: <HackathonPage />
        // },
        {
            path: '/policies/popi',
            element: <POPIPolicyPage />
        },
        {
            path: '/updates',
            element: <UpdatePage />
        }
    ]
};

export default LoginRoutes;
