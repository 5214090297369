import { FormattedMessage } from 'react-intl';

// assets
import { IconChartBar } from '@tabler/icons-react';

// ==============================|| MENU ITEMS - UPLOAD PAGE ||============================== //

const icons = {
    IconChartBar
};
const dashboardPage = {
    id: 'dashboard-page',
    title: <FormattedMessage id="Dashboard" />,
    icon: icons.IconChartBar,
    type: 'group',
    url: '/dashboard'
};

export default dashboardPage;
