// src/utils/authHelper.js

import jwt_decode from 'jwt-decode';
import {validateToken} from "utils/axios";
import Logger from "utils/Logger";

export const isAdmin = () => {
    try {
        const token = localStorage.getItem('serviceToken');
        if (!token) return false;

        if (!validateToken(token)) return false;


        const decoded = jwt_decode(token);

        // Check for 'admin' role in realm_access.roles
        const roles = decoded.realm_access?.roles;

        // return true or false value if it contains admin or not
        return roles && roles.includes('admin');
    } catch (error) {
        Logger.error('Failed to decode token or token is invalid:', error);
        return false;
    }
};
