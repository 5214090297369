import { lazy } from 'react';

// project imports
import AdminGuard from 'utils/route-guard/AdminGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// sample page routing
const AdminDashboard = Loadable(lazy(() => import('../views/admin/dashboard')));


// ==============================|| Admin ROUTING ||============================== //

const AdminRoutes = {
    path: '/',
    element: (
        <AdminGuard>
            <MainLayout />
        </AdminGuard>
    ),
    children: [
        {
            path: 'admin/dashboard',
            element: <AdminDashboard />
        }
    ]
};

export default AdminRoutes;
