import React, { useEffect, useState } from 'react';
import DOMPurify from 'dompurify';
import MainCard from "ui-component/cards/MainCard";
import { Typography, CircularProgress } from "@mui/material";
import SubCard from "ui-component/cards/SubCard";
import moment from 'moment';

// Format the month specified so that it follows the html file
const formatMonth = (month) => (month < 10 ? `0${month}` : `${month}`);

// Function to extract everything from `<!DOCTYPE html>` to `</html>`
const extractHtmlContent = (html) => {
    const regex = /<!DOCTYPE html>[\s\S]*<\/html>/;
    const match = html.match(regex);

    // Return the match or the default content if no match is found
    return match ? match[0] : html;
};

// Timeout duration in milliseconds
const TIMEOUT_DURATION = 5000; // 5 seconds timeout due to missing file urls getting stuck on pending

/**
 * Fetches data from a specified URL with a provided timeout period.
 * @param {string} url - The URL to fetch data from.
 * @param {number} timeout - The timeout period in milliseconds.
 * @returns {Promise} A Promise that resolves with the fetched data or rejects with an error.
 */
const fetchWithTimeout = (url, timeout) => {
    return Promise.race([
        fetch(url).then((response) => response.ok ? response.text() : Promise.reject('File not found')),
        new Promise((_, reject) => setTimeout(() => reject(new Error('Timeout exceeded')), timeout))
    ]);
};

/**
 * Function to update page with HTML content fetched from server.
 * Manages fetching multiple HTML files based on current and previous months.
 * Cleans and sanitizes the fetched HTML content before displaying on the page.
 * Displays loading spinner while fetching data.
 * Displays content on the page once data is fetched.
 */
const UpdatePage = () => {
    const [htmlContents, setHtmlContents] = useState([]);
    const [loading, setLoading] = useState(true); // Track the loading state
    const BASE_URL = '/report-summaries/summaries'; // Your base URL for HTML files

    // Fetches the HTML files
    useEffect(() => {
        const fetchHtmlFiles = async () => {
            const currentDate = new Date();
            const currentYear = currentDate.getFullYear();
            const currentMonth = currentDate.getMonth() + 1; // Month is 0-indexed in JS

            const monthsToFetch = [];

            // Collect the current month and any number of previous months
            for (let i = 0; i < 6; i++) {
                const month = currentMonth - i;
                const year = currentYear - Math.floor((currentMonth - i - 1) / 12); // Adjust the year for previous months
                const adjustedMonth = (month - 1 + 12) % 12 + 1; // Ensure months wrap correctly (1-12)

                monthsToFetch.push({
                    fileName: `summariseAndConvertToHTML_${year}-${formatMonth(adjustedMonth)}-report.html`,
                    year,
                    month: adjustedMonth,
                });
            }

            // Attempt to fetch each HTML file and store the results
            const results = await Promise.all(
                monthsToFetch.map(async ({ fileName, year, month }) => {
                    try {
                        const url = `${BASE_URL}/${fileName}`;
                        const html = await fetchWithTimeout(url, TIMEOUT_DURATION); // Use the timeout-enabled fetch

                        // Clean up the HTML and sanitize it
                        const cleanedHtml = extractHtmlContent(html); // Clean unwanted content
                        const sanitizedHtml = DOMPurify.sanitize(cleanedHtml); // Sanitize the cleaned HTML
                        return { sanitizedHtml, year, month }; // Return sanitized HTML with month and year
                    } catch (error) {
                        console.error(`Error fetching ${fileName}:`, error);
                        return { sanitizedHtml: null, year, month }; // Return null if fetch fails
                    }
                })
            );

            setHtmlContents(results);
            setLoading(false);
        };

        fetchHtmlFiles();
    }, []);

    return (
        <MainCard>
            <Typography variant="h1" pb={4} textAlign={"center"}>
                Casabio updates
            </Typography>

            {/* Show loading spinner until data is fetched */}
            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <CircularProgress />
                </div>
            ) : (
                htmlContents.length > 0 ? (
                    htmlContents.map((content, index) => (
                        <SubCard key={index} sx={{ display: "flex" }}>
                            <Typography variant="h3" py={1}>
                                {moment(`${content.year}-${content.month}-01`, 'YYYY-MM-DD').format('MMMM YYYY')}
                            </Typography>
                            {content.sanitizedHtml ? (
                                <div dangerouslySetInnerHTML={{ __html: content.sanitizedHtml }} />
                            ) : (
                                <Typography variant="body1" color="textSecondary">
                                    No report found for this month.
                                </Typography>
                            )}
                        </SubCard>
                    ))
                ) : (
                    <SubCard>
                        <Typography variant="body1" py={1}>
                            No update reports found.
                        </Typography>
                    </SubCard>
                )
            )}
        </MainCard>
    );
};

export default UpdatePage;
