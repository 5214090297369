import uploadPage from './upload-page';
import profilePage from './profile-page';
import myContributionsPage from './my-contributions-page';
import dashboardPage from './dashboard-page'
import ExplorePage from './explore-page';
import siteDetails from './site-details';
import AdminPage from './admin-page';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [siteDetails, profilePage, uploadPage, myContributionsPage, ExplorePage, dashboardPage, AdminPage]
};

export default menuItems;